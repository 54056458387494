import { Tooltip } from '@/components/basic';
import { LoadingSpinner } from '@/components/util/LoadingSpinner';
import useAreas from '@/hooks/useAreas';
import {
  RESERVE_TEXTS,
  SaveReservationData,
  SaveReservationDataUsers
} from '@/lib/utils-reserve';
import { IResource, Resource, ResourceType } from '@gettactic/api';
import { useMemo } from 'react';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';

type ReserveFieldResourcesListProps = {
  resourceType: ResourceType;
  form: UseFormReturn<SaveReservationData>;
  resourcesList: Resource[] | null | undefined;
  defaultValue: Resource | null;
  officeId: string;
  errors: FieldErrors<SaveReservationData>;
};

export function isOverCapacity(
  users: SaveReservationDataUsers,
  resource: IResource | null = null
) {
  return !!users && !!resource && users.length > resource.available_slots;
}

type TooltipOverCapacityProps = {
  users: SaveReservationDataUsers;
  resource: IResource;
  entity: ResourceType;
};

export function TooltipOverCapacity({
  users,
  resource,
  entity
}: TooltipOverCapacityProps) {
  return !!resource && !!users ? (
    <div className="mt-2 flex max-w-[155px] items-center rounded-xl bg-yellow-50 px-2 py-1">
      <span className="text-yellow-800">Over Capacity</span>
      <Tooltip testId="capacity-tooltip">
        <div className="w-48 font-semibold">
          {RESERVE_TEXTS.overCapacityTitle[entity]}
        </div>
        <div className="text-sm">
          {RESERVE_TEXTS.overCapacityTotalSlots[entity]}:&nbsp;
          {resource.available_slots}
        </div>
        <div className="text-sm">
          {RESERVE_TEXTS.overCapacityUsersLength[entity]}:&nbsp;
          <span className="text-primary" data-testid="users-length">
            {users.length}
          </span>
        </div>
      </Tooltip>
    </div>
  ) : null;
}

export default function ReserveFieldResourcesList({
  resourcesList,
  defaultValue: def,
  form,
  resourceType,
  officeId,
  errors
}: ReserveFieldResourcesListProps) {
  const { areas } = useAreas(officeId);
  const users = form.watch('users');
  const resource = form.watch('resource');
  const showTooltipOverCapacity = isOverCapacity(users, resource);
  const options = useMemo(
    () =>
      (resourcesList ?? []).map((res) => ({
        ...res,
        label: res.name,
        value: res.id
      })),
    [resourcesList]
  );
  const defaultValue = useMemo(
    () => options.find((res) => res.id === def?.id),
    [def, options]
  );

  const showEmpty = options.length === 0 && !defaultValue;
  if (!resourcesList) {
    return <LoadingSpinner color="text-secondary" />;
  }

  return (
    <>
      {showEmpty ? (
        <p className="text-primary">{`No ${resourceType === 'meeting_room' ? 'rooms' : 'desks'} available to reserve!`}</p>
      ) : (
        <>
          <Controller
            control={form.control}
            name={'resource'}
            rules={{ required: true }}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
              <Select
                onChange={onChange}
                value={value}
                placeholder={RESERVE_TEXTS.selectResource[resourceType]}
                className={'resource-select'}
                classNamePrefix="react-select"
                isOptionDisabled={(option) => option.type === 'area'}
                noOptionsMessage={() => 'No availability'}
                components={{
                  GroupHeading: (props) => (
                    <div className="mx-2 h-8 border-gray-50 border-b-2 pl-2 font-bold">
                      {props.data.label}
                    </div>
                  ),
                  Option: (props) => (
                    <components.Option {...props}>
                      {props.data.name}
                      {props.data.available_slots > 0 && (
                        <span className="ml-2 rounded-full bg-green-100 px-2.5 py-0.5 font-medium text-green-800 text-xs">
                          {props.data.available_slots}
                        </span>
                      )}
                      {areas &&
                      props.data.parent_id &&
                      areas.byId[props.data.parent_id] ? (
                        <span className="ml-1 rounded-md bg-gray-100 px-2.5 py-0.5 text-xs">
                          {areas.byId[props.data.parent_id].name}
                        </span>
                      ) : null}
                    </components.Option>
                  )
                }}
                options={options}
              />
            )}
          />

          {errors.resource && errors.resource.type === 'required' && (
            <p className="pl-1 text-primary">
              {RESERVE_TEXTS.selectResource[resourceType]}
            </p>
          )}
          {errors.resource && errors.resource.type !== 'required' && (
            <p className="text-primary">
              Hmm, something went wrong. Can you try again?
            </p>
          )}
          {showTooltipOverCapacity && (
            <TooltipOverCapacity
              users={users}
              resource={resource}
              entity={resourceType}
            />
          )}
        </>
      )}
    </>
  );
}
