import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { api } from '@/lib/api/api';

export function useOfficeRestrictions(
  officeId: string | null,
  startTime: Date,
  weekDatesStr: string[],
  durationMins: number,
  resourceIds: string[]
) {
  const dates = weekDatesStr.map((w) => {
    return `${w}T${format(startTime, 'HH:mm:ss')}.000Z`;
  });
  const { data: restrictions } = useQuery(
    ['organizations.restrictions', officeId, resourceIds, dates, durationMins],
    async () => {
      const res = await api.client.organizations.restrictions(
        officeId ?? '',
        resourceIds,
        dates,
        durationMins.toString()
      );
      return res.result;
    },
    {
      enabled: !!officeId
    }
  );
  return { restrictions };
}
