import React from 'react';
import { FieldErrors, FormProps } from 'react-hook-form';
import { getTimeForReservation } from '@/lib/utils';
import { RESERVE_TEXTS } from '@/lib/utils-reserve';
import { SaveReservationData } from '@/lib/utils-reserve';
import { FormField } from '@/components/ui/form';
import { DatePicker } from '../../basic/DatePicker/DatePicker';

type ReserveTimeProps = {
  multipleDays: boolean;
  form: FormProps<any>;
  defaultValue: Date;
  weekDates: Date[];
  setStartTime: React.Dispatch<React.SetStateAction<Date>>;
  officeTimeZone: string;
  officeId?: string;
  errors: FieldErrors<SaveReservationData>;
};

export default function ReserveFieldTime({
  multipleDays,
  form,
  defaultValue,
  weekDates,
  setStartTime,
  officeTimeZone,
  officeId,
  errors
}: ReserveTimeProps) {
  return (
    <>
      <FormField
        control={form.control}
        name="time"
        defaultValue={defaultValue}
        render={({ field }) => (
          <DatePicker
            onChange={(
              date: Date | null,
              event: React.SyntheticEvent<any, Event>
            ) => {
              if (!weekDates[0]) {
                //prevent calculations with an undefined date
                return;
              }
              const newTime = getTimeForReservation(
                weekDates[0],
                date,
                officeTimeZone,
                officeId
              );
              field.onChange(newTime, event);
              if (date !== null) {
                setStartTime(newTime);
              }
            }}
            selected={field.value}
            className="input w-full rounded-md"
            calendarClassName="tailwind-timepicker"
            wrapperClassName="tailwind-timepicker-wrapper"
            placeholderText={RESERVE_TEXTS.selectTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={RESERVE_TEXTS.selectTimeTitle}
            timeFormat="h:mmaaa"
            dateFormat="h:mmaaa"
          />
        )}
      />
      {errors.time && (
        <p className="text-primary">Specify a valid start time</p>
      )}
    </>
  );
}
