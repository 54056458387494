import { CommonContext } from '../contexts/CommonContext';
import { useContext } from 'react';

const useCommonContext = () => {
  const context = useContext(CommonContext);

  if (!context)
    throw new Error('Common context must be use inside CommonProvider');

  return context;
};

export default useCommonContext;
