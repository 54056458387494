import { DivisionOfficeRender } from '@/components/layouts/DivisionOfficeSelector/DivisionOfficeRender';
import { DivisionTree } from '@/components/layouts/DivisionOfficeSelector/DivisionTree';
import {
  DivisionOfficeSelectorProvider,
  DivisionOfficeSelectorState
} from '@/components/layouts/DivisionOfficeSelector/helpers';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import LoadingSpinner from '@/components/util/LoadingSpinner';
import { DivisionLabel } from '@gettactic/components/src/data-display/labels/DivisionLabel';
import { OfficeLabel } from '@gettactic/components/src/data-display/labels/OfficeLabel';
import { useDivisionsMenuQuery } from '@gettactic/hooks';
import { CheckIcon } from '@heroicons/react/outline';
import { IconChevronDown } from '@tabler/icons-react';
import React from 'react';

export const DivisionOfficeSelector: React.FC<DivisionOfficeSelectorState> = (
  props
) => {
  const selectedOffice = props.selectedOffice;
  const divisions = useDivisionsMenuQuery();
  const root = divisions.data;

  if (!root) {
    return (
      <div className="mx-auto mt-8 flex max-w-7xl items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <DivisionOfficeSelectorProvider value={props}>
      <DropdownMenu open={props.isOpen} onOpenChange={props.onOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="min-w-[225px] justify-start">
            <div className="flex w-full items-center justify-between">
              <div className="flex gap-2">
                {props.hasAllItem &&
                  !props.selectedOffice &&
                  !props.selectedDivision && (
                    <span>All Offices and Divisions</span>
                  )}
                {props.selectedOffice && (
                  <OfficeLabel office={selectedOffice} />
                )}
                {props.selectedDivision && (
                  <DivisionLabel division={props.selectedDivision} />
                )}
              </div>
              <IconChevronDown size={16} />
            </div>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-56" align="start">
          {props.hasAllItem && (
            <DropdownMenuItem
              key={'all'}
              onClick={() => {
                props.onSelectOffice?.(undefined);
                props.onSelectDivision?.(undefined);
              }}
            >
              All Offices and Divisions{' '}
              {!props.selectedOffice && !props.selectedDivision ? (
                <CheckIcon className="ml-auto h-5 w-5 text-primary" />
              ) : null}
            </DropdownMenuItem>
          )}
          <DropdownMenuGroup>
            {root.tree.children.map((tree) => (
              <DivisionTree key={tree.id} tree={tree} level={0} />
            ))}
            <DivisionOfficeRender tree={root.tree} />
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </DivisionOfficeSelectorProvider>
  );
};
