import { Division } from '@gettactic/api';
import React from 'react';
import { Flex } from '../../../primitives';

export interface DivisionLabelProps {
  division: Division | undefined | null;
  showType?: boolean;
}
export const DivisionLabel: React.FC<DivisionLabelProps> = ({
  division,
  showType = true
}) => {
  if (!division) return null;
  return (
    <Flex className={'gap-2'}>
      {showType && (
        <span className={'text-gray-400 capitalize'}>{division.type}</span>
      )}
      {division.name}
    </Flex>
  );
};
