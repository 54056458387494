import React, { useMemo } from 'react';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { Office, ResourceType } from '@gettactic/api';
import { EntityType } from '@/lib/types/EntityType';
import {
  getMaxDateAllowedToReserve,
  getMinDateAllowedToReserve,
  getTimeForReservation
} from '@/lib/utils';
import { RESERVE_TEXTS, SaveReservationData } from '@/lib/utils-reserve';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { DatePicker } from '../../basic/DatePicker/DatePicker';

type ReserveStartDateProps = {
  form: UseFormReturn<any>;
  defaultValue: Date[];
  startTime: Date;
  officeTimeZone: string;
  weekDates: Date[];
  office?: Office;
  resType: ResourceType;
  errors: FieldErrors<SaveReservationData>;
};

export default function ReserveFieldStartDate({
  form,
  defaultValue,
  startTime,
  weekDates,
  officeTimeZone,
  office,
  resType,
  errors
}: ReserveStartDateProps) {
  const minAllowedReserveDate = useMemo(
    () => getMinDateAllowedToReserve(officeTimeZone, weekDates),
    [officeTimeZone, weekDates]
  );
  const maxAllowedReserveDate = useMemo(
    () => getMaxDateAllowedToReserve(officeTimeZone, office, resType),
    [office, officeTimeZone, resType]
  );
  return (
    <>
      <FormField
        control={form.control}
        name="weekDates"
        render={({ field }) => (
          <DatePicker
            minDate={minAllowedReserveDate}
            maxDate={maxAllowedReserveDate ?? undefined}
            onChange={(date: Date | null, event) => {
              field.onChange(date, event);
              if (date !== null) {
                const newTime = getTimeForReservation(
                  date,
                  startTime,
                  officeTimeZone,
                  office?.id
                );
                form.setValue('time', newTime);
                form.setValue('weekDates', [date], { shouldValidate: true });
              }
            }}
            selected={field.value[0]}
            className="input w-full rounded-md"
            calendarClassName="tailwind-datepicker"
            placeholderText={RESERVE_TEXTS.selectStart}
          />
        )}
      />
      {errors.weekDates ? (
        <p className="text-primary">Please choose a valid date</p>
      ) : null}
    </>
  );
}
