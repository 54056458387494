import { Alert } from '@/components/basic';

type ApprovalRequiredProps = {
  className: string;
};
export default function ApprovalRequiredAlert({
  className = ''
}: ApprovalRequiredProps): JSX.Element {
  return (
    <div className={className}>
      <Alert
        variant="tacticAlert"
        canDismiss={false}
        alertTitle={`Approval Required`}
        alertMessage="This workspace requires approval for reservations. Upon making a reservation an office admin will be notified and will need to approve or deny the reservation."
        className="mt-2 px-4 py-2"
      ></Alert>
    </div>
  );
}
