import {
  findTimeZone,
  getZonedTime,
  listTimeZones
} from 'timezone-support/dist/index-1970-2038';
import { formatZonedTime } from 'timezone-support/dist/parse-format';

export type TimeZone = {
  id: string;
  label: string;
  offset: number;
};
// Source: https://github.com/uber/baseweb/pull/4382/
export const buildTimezones = (
  compareDate: Date,
  includeAbbreviations = true
): TimeZone[] =>
  listTimeZones()
    .map((zone) => {
      const timezone = findTimeZone(zone);
      const zonedTime = getZonedTime(compareDate, timezone);
      const zonedTimeOffset = zonedTime?.zone?.offset || 0;

      const formattedOffset =
        (zonedTimeOffset < 0 ? '+' : '-') + Math.abs(zonedTimeOffset / 60);
      const abbreviation = formatZonedTime(zonedTime, 'z');
      const formatted =
        `(GMT ${formattedOffset}) ${zone}${includeAbbreviations ? ` - ${abbreviation}` : ''}`.replace(
          '_',
          ' '
        );

      const option = {
        id: zone,
        label: formatted,
        offset: zonedTimeOffset
      };

      // Remove offensive name
      // https://www.euronews.com/2019/06/21/kyiv-or-kiev-why-does-it-matter-so-much-to-ukrainians
      if (option.id === 'Europe/Kiev') {
        option.label = option.label.replace(/Kiev/, 'Kyiv');
      }

      return option;
    })
    // Formats 'noisy' timezones without a letter acronym.
    .map((option) => {
      const rgx = /(\s-\s(\+|-)\d\d\d?\d?)$/;
      const matches = option.label.match(rgx);
      if (matches) {
        const prefix = matches[0];
        option.label = option.label.split(prefix)[0];
      }
      return option;
    })
    // Sorts W -> E, prioritizes america. could be more nuanced based on system tz but simple for now
    .sort((a, b) => {
      const offsetDelta = b.offset - a.offset;
      if (offsetDelta !== 0) return offsetDelta;
      return a.label.localeCompare(b.label);
    });
