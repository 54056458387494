import { pad } from '@gettactic/helpers/src/numbers/pad';
import { getObjectKeys } from '@gettactic/helpers/src/objects/getObjectKeys';
import React from 'react';

export interface UseMaskInputReturn {
  onInput: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export interface UseMaskInputOptions {
  renderValue?: (value: string) => string;
  prepareValue?: (value: string) => string;
  customPatternCharacterRegexps?: Record<string, RegExp>;
  placeholderChar?: string;
}

const defaultPatternCharacterRegexps = {
  '9': /\d/,
  A: /[a-zA-Z]/,
  '*': /./
};

export const useMaskInput = (
  maskPattern: string,
  options?: UseMaskInputOptions
): UseMaskInputReturn => {
  const placeholderChar = options?.placeholderChar || ' ';
  const patternCharacterRegexps = {
    ...defaultPatternCharacterRegexps,
    ...(options?.customPatternCharacterRegexps || {})
  };
  const patternKeys = getObjectKeys(patternCharacterRegexps);
  const onInput = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const input = e.currentTarget;
      const value = input.value;
      input.value = applyMask(value, maskPattern);
    },
    [maskPattern]
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const input = event.currentTarget;
      const cursorPosition = input.selectionStart || 0;
      const currentValue = input.value;

      if (event.key === 'Backspace') {
        if (cursorPosition > 0) {
          event.preventDefault();
          input.value =
            currentValue.substring(0, cursorPosition - 1) +
            placeholderChar +
            currentValue.substring(cursorPosition);
          input.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
        }
      } else if (event.key === 'Delete') {
        if (cursorPosition < currentValue.length) {
          event.preventDefault();
          input.value =
            currentValue.substring(0, cursorPosition) +
            placeholderChar +
            currentValue.substring(cursorPosition + 1);
          input.setSelectionRange(cursorPosition, cursorPosition);
        }
      }
    },
    [placeholderChar]
  );

  const applyMask = (inputValue: string, pattern: string): string => {
    let result = '';
    let maskIndex = 0;
    let valueIndex = 0;

    const value = options?.prepareValue
      ? options.prepareValue(inputValue)
      : inputValue;

    const currentPatternType = pattern[value.length - 1];
    let nearestPreviousDifferentPatternTypeIndex = value.length - 1;
    let nearestNextDifferentPatternTypeIndex = value.length - 1;

    for (let i = nearestPreviousDifferentPatternTypeIndex; i >= 0; i--) {
      if (i === 0) nearestPreviousDifferentPatternTypeIndex = 0;
      if (pattern[i] !== currentPatternType) {
        nearestPreviousDifferentPatternTypeIndex = i;
        break;
      }
    }

    for (
      let i = nearestNextDifferentPatternTypeIndex;
      i <= pattern.length;
      i++
    ) {
      if (pattern[i] !== currentPatternType) {
        nearestNextDifferentPatternTypeIndex = i;
        break;
      }
    }

    const nearestNextDifferentPatternType =
      pattern[nearestNextDifferentPatternTypeIndex];

    while (maskIndex < pattern.length && valueIndex < value.length) {
      if (patternCharacterRegexps[pattern[maskIndex]]) {
        if (
          patternCharacterRegexps[pattern[maskIndex]].test(value[valueIndex])
        ) {
          result += value[valueIndex];
          maskIndex++;
        } else {
          if (nearestNextDifferentPatternType) {
            if (
              (patternKeys.includes(nearestNextDifferentPatternType as never) &&
                patternCharacterRegexps[nearestNextDifferentPatternType]?.test(
                  value[valueIndex]
                )) ||
              nearestNextDifferentPatternType === value[valueIndex]
            ) {
              if (currentPatternType === '9') {
                const startSlice =
                  nearestPreviousDifferentPatternTypeIndex === 0
                    ? 0
                    : nearestPreviousDifferentPatternTypeIndex + 1;

                const patternPartSize =
                  nearestNextDifferentPatternTypeIndex - startSlice;

                const newString = String(value).slice(
                  nearestPreviousDifferentPatternTypeIndex === 0
                    ? 0
                    : nearestPreviousDifferentPatternTypeIndex + 1,
                  value.length - 1
                );

                const padString = pad(newString, patternPartSize);
                const charDiff = padString.length - newString.length;

                result =
                  result.slice(0, startSlice) + padString + value[valueIndex];
                maskIndex += charDiff + 1;
                valueIndex++;
              }
            }
          }
        }
        valueIndex++;
      } else {
        if (
          value[valueIndex] === pattern[maskIndex] ||
          value[valueIndex] === options?.placeholderChar
        ) {
          result += pattern[maskIndex];
          maskIndex++;
          valueIndex++;
        } else {
          while (
            maskIndex < pattern.length &&
            pattern[maskIndex] !== value[valueIndex]
          ) {
            result += pattern[maskIndex];
            maskIndex++;
          }
        }
      }
    }

    while (maskIndex < pattern.length) {
      if (patternKeys.includes(pattern[maskIndex] as never)) {
        break;
      }
      result += pattern[maskIndex];
      maskIndex++;
    }

    if (options?.renderValue) {
      return options.renderValue(result);
    }

    return result;
  };

  return { onInput, onKeyDown };
};
