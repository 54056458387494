import { api } from '@/lib/api/api';
import { UserRoles } from '@gettactic/api';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const DEFAULT_PAGE_SIZE = 20;

export default function useUserList(
  query: string | null = null,
  roles: UserRoles[] | null = null,
  cursor: string | null = null,
  managed = false
) {
  const userList = useQuery(
    ['organizations.usersWithOptions', query, roles, cursor, managed],
    async () => {
      const res = await api.client.organizations.usersWithOptions(
        query,
        roles,
        {
          cursor,
          limit: null
        },
        managed
      );
      return res.result;
    }
  );
  return userList;
}

export function useUserListByIds(ids: string[]) {
  const userList = useQuery(
    ['organizations.usersWithOptions', { ids }],
    async () => {
      const res = await api.client.organizations.usersById(ids);
      return res;
    }
  );
  return userList;
}

export function useUserListPaginated(
  query: string | null,
  limit: number,
  filters: { [idx: string]: string | null } = {},
  roles: UserRoles[] = [],
  managed = false
) {
  const params = new URLSearchParams();
  for (const [k, v] of Object.entries(filters)) {
    if (v !== null && typeof v !== 'undefined') {
      params.set(k, v);
    }
  }
  if (query && query.trim() !== '') {
    params.set('query', query);
  }
  params.set('limit', limit.toString());
  roles.forEach((role) => params.append('role', role));
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status
  } = useInfiniteQuery(
    ['organizations.usersPaginated', params.toString(), managed],
    async ({ pageParam: cursor }) => {
      if (cursor) {
        params.set('cursor', cursor);
      }
      const res = await api.client.organizations.usersPaginated(
        params,
        managed
      );
      return res.result;
    },
    {
      getNextPageParam: (lastPage) =>
        (lastPage?.elements.length ?? 0) < limit
          ? undefined
          : lastPage?.cursor ?? undefined
    }
  );

  const allPages = useMemo(
    () =>
      (data?.pages || [])
        .map((x) => x?.elements ?? [])
        .reduce((arr, els) => {
          return arr.concat(els);
        }, []),
    [data]
  );

  return {
    data,
    allPages,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status
  };
}
