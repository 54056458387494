import { UseFormReturn } from 'react-hook-form';
import { RESERVE_TEXTS } from '@/lib/utils-reserve';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

type ReserveTitleProps = {
  form: UseFormReturn<any>;
};

export default function ReserveFieldTitle({ form }: ReserveTitleProps) {
  return (
    <>
      <FormField
        control={form.control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <FormControl className="text-base text-gray-800">
              <Input placeholder={RESERVE_TEXTS.addTitle} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
