import {
  loadDurationOptions,
  useReserveDurationInputMask
} from '@/components/reserve/ReserveFieldDuration/helpers';
import { FormField, FormItem, FormMessage } from '@/components/ui/form';
import {
  DurationOption,
  RESERVE_TEXTS,
  SaveReservationData,
  getDurationOptionFromMinutes
} from '@/lib/utils-reserve';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

type ReserveDurationProps = {
  form: UseFormReturn<SaveReservationData>;
  defaultValue: number;
  setDurationMin: React.Dispatch<React.SetStateAction<number>>;
  errors: FieldErrors<SaveReservationData>;
  durationOptions: DurationOption[];
  startTime: Date;
};
export default function ReserveFieldDuration({
  form,
  defaultValue,
  durationOptions: _durationOptions,
  setDurationMin,
  startTime
}: ReserveDurationProps) {
  const durationOptions = useMemo(() => {
    const options = [..._durationOptions];
    if (!options.find((option) => option.value === defaultValue)) {
      const defaultOption = getDurationOptionFromMinutes(
        startTime,
        defaultValue
      );
      if (defaultOption) {
        options.push(defaultOption);
      }
    }
    return options.sort((a, b) => a.value - b.value);
  }, [defaultValue, _durationOptions, startTime]);

  // State and useEffect are here to properly update the label of the select when the startTime changes
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: number;
  } | null>(
    durationOptions.find((option) => option.value === defaultValue) || null
  );

  useEffect(() => {
    const defaultOption = durationOptions.find(
      (option) => option.value === defaultValue
    );

    setSelectedOption(defaultOption || null);
  }, [durationOptions, defaultValue]);

  const mask = useReserveDurationInputMask();

  return (
    <>
      <FormField
        control={form.control}
        name="duration"
        rules={{ required: true }}
        defaultValue={selectedOption || undefined}
        render={({ field }) => (
          <FormItem>
            <AsyncSelect
              formatOptionLabel={(option) => (
                <div>
                  <div>{option.label}</div>
                  <div className={'text-muted-foreground'}>
                    {(option as DurationOption).durationLabel}
                  </div>
                </div>
              )}
              components={{
                SingleValue: (props) => (
                  <components.SingleValue {...props}>
                    {props.data.label}
                  </components.SingleValue>
                ),
                Input: (props) => (
                  <components.Input {...props} size={undefined} {...mask} />
                )
              }}
              onChange={(d, event) => {
                field.onChange(d, event);
                if (d) {
                  setDurationMin(d.value);
                  setSelectedOption(d);
                }
              }}
              value={selectedOption}
              loadOptions={(filter, callback) =>
                callback(
                  loadDurationOptions(filter, startTime, durationOptions)
                )
              }
              defaultOptions={durationOptions}
              placeholder={RESERVE_TEXTS.selectDuration}
              classNamePrefix="react-select"
            />
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
