import React, { useEffect, useState } from 'react';
import { classNames } from '@/lib/classNames';
import { UseFormReturn } from 'react-hook-form';
import { FormField } from '@/components/ui/form';
import { format } from 'date-fns';
import { useAuthenticated } from '@/lib/api/appUser';

type WeekdayPickerProps = {
  form: UseFormReturn<any>;
  weekDates: Date[];
  currentWeek: Date[];
  currentDayIdx: number;
  singleSelect?: boolean;
};

export default function WeekdayPicker({
  form,
  weekDates,
  currentWeek,
  currentDayIdx,
  singleSelect = false
}: WeekdayPickerProps) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const hideWeekends =
    authenticatedUser.organization?.features?.includes('hide.weekends') ??
    false;
  const [localWeekDates, setLocalWeekDates] = useState<Date[]>([]);
  const weekDays: { label: string; value: number }[] = [
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 }
  ];

  useEffect(() => {
    setLocalWeekDates(weekDates);
  }, [weekDates]);

  const handleDaySelection = (day: number) => {
    const changeDate: Date = currentWeek[day];
    const w: Date[] = [...localWeekDates];
    const idx = w.findIndex(
      (x) => format(x, 'yyyy-MM-dd') === format(changeDate, 'yyyy-MM-dd')
    );

    if (idx === -1) {
      form.setValue(
        'weekDates',
        singleSelect ? [changeDate] : [...w, changeDate]
      );
    } else {
      w.splice(idx, 1);
      form.setValue('weekDates', [...w]);
    }
  };

  const checkSelection = (day: number): boolean => {
    for (let i = 0; i < localWeekDates.length; i++) {
      if (
        day === localWeekDates[i].getDay() &&
        currentWeek[day].getDate() === localWeekDates[i].getDate()
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <FormField
        control={form.control}
        name="weekDates"
        render={({ field }) => (
          <div className="mt-2 flex items-center">
            <div
              className={`${hideWeekends ? 'basis-2/4' : 'basis-1/4'}  text-lg font-bold`}
            >
              Book for
            </div>
            <div
              className={`${hideWeekends ? 'basis-2/4' : 'basis-3/4'} flex items-center justify-between space-x-1`}
            >
              {weekDays
                .filter((day) =>
                  hideWeekends ? day.value !== 0 && day.value !== 6 : true
                )
                .map((day) => {
                  return (
                    <div
                      key={day.value}
                      onClick={() =>
                        currentDayIdx <= day.value
                          ? handleDaySelection(day.value)
                          : null
                      }
                      className={classNames(
                        'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border  text-center font-bold capitalize ',
                        checkSelection(day.value)
                          ? 'bg-primary text-white '
                          : 'border-gray-300 text-secondary-bg',
                        currentDayIdx > day.value && 'opacity-50'
                      )}
                      title={day.label}
                    >
                      {day.label[0]}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      />
    </>
  );
}
