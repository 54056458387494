import {
  IHour,
  Restriction,
  cover24Hours,
  parseDateOnlyString,
  parseTimeOnly,
  prettyTimeOnly,
  sortRestrictions
} from '@gettactic/api';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { Alert } from '@/components/basic';

type OfficeClosedProps = {
  restrictions: Restriction[];
  className?: string;
  resourceId?: string | null;
};

function getDateWindowMessage(hour: IHour): string {
  const since = hour.since ? parseDateOnlyString(hour.since) : null;
  const until = hour.until ? parseDateOnlyString(hour.until) : null;
  const sinceFormatted = since ? format(since, 'M/dd/yy') : '';
  const untilFormatted = until ? format(until, 'M/dd/yy') : '';
  if (!since && !until) {
    return 'Always';
  }
  if (sinceFormatted === untilFormatted) {
    return sinceFormatted;
  }
  if (since && !until) {
    return sinceFormatted;
  }
  if (until && !since) {
    return untilFormatted;
  }
  return `${sinceFormatted} until ${untilFormatted}`;
}

function HourMessage({ hour }: { hour: IHour }) {
  const is24hours = cover24Hours(
    parseTimeOnly(hour.start_time),
    parseTimeOnly(hour.end_time)
  );
  const startTime = prettyTimeOnly(hour.start_time);
  const endTime = prettyTimeOnly(hour.end_time);
  const dateWindowMsg = getDateWindowMessage(hour);
  const hourMessage = is24hours
    ? `all day`
    : ` between ${startTime} and ${endTime}`;
  if (hour.type === 'holiday') {
    return (
      <span>
        Holiday: {hour.name} - {dateWindowMsg}, {hourMessage}
      </span>
    );
  } else if (hour.type === 'weekday' && hour.days?.length) {
    const day = hour.days[0];
    return (
      <span>
        On <span className="capitalize">{day}</span>s the office is{' '}
        {hour.is_available ? 'only open' : 'closed'} <span>{hourMessage}</span>
      </span>
    );
  } else if (hour.type === 'rule') {
    return (
      <span>
        {dateWindowMsg} {!hour.resource_id ? 'the office' : 'the resource'} is
        closed: <span className="underline">{hour.name}</span>, {hourMessage}
      </span>
    );
  }
  return <span>Unknown reason</span>;
}

function RestrictionErrorRender({
  restriction: x
}: { restriction: Restriction }) {
  if (x.error === 'denied_team') {
    return (
      <p>You do not belong to any of the teams assigned to this resource.</p>
    );
  } else if (x.error === 'denied_user') {
    return <p>You have not been assigned to this resource.</p>;
  } else if (x.error === 'resource_access_not_available') {
    return <p>This resource was marked as not available by an admin.</p>;
  } else if (x.error === 'not_enough_slots') {
    return (
      <p>There's not enough space to reserve some of the selected resources.</p>
    );
  } else if (x.error === 'request_too_far_in_time') {
    return (
      <p>
        Your office admin has restricted reservations from being made this far
        in the future.
      </p>
    );
  } else if (x.error === 'office_hours_not_available' && x.office_hour) {
    return (
      <p>
        <HourMessage hour={x.office_hour}></HourMessage>
      </p>
    );
  } else if (x.error === 'resource_hours_not_available' && x.resource_hour) {
    return (
      <p>
        <HourMessage hour={x.resource_hour}></HourMessage>
      </p>
    );
  } else if (x.error === 'request_duration_cant_span_to_a_new_day') {
    // this should not happen
    console.error('Span should not be allowed by frontend validations: ', x);
    return <p>Not allowed to reserve a window time larger than 24 hs</p>;
  } else if (x.error === 'overlapping_disabled') {
    return (
      <p>
        You already have a reservation during this period with the same resource
        type.
      </p>
    );
  }
  console.error('Unrecognized error showing generic message: ', x);
  return <p>You are not allowed to reserve</p>;
}

export default function RestrictionAlert({
  restrictions,
  className = '',
  resourceId
}: OfficeClosedProps): JSX.Element {
  const filtered = useMemo(
    () => sortRestrictions(restrictions, resourceId),
    [restrictions, resourceId]
  );
  if (!filtered.length) {
    return <></>;
  }

  return (
    <div className={className}>
      <Alert
        variant="tacticAlert"
        alertMessage=""
        canDismiss={false}
        alertTitle={
          resourceId
            ? "This resource can't be reserved"
            : 'Some of the selected days have restrictions applied'
        }
        className="mt-2 px-4 py-2"
      >
        {filtered.map((x, idx) => (
          <RestrictionErrorRender restriction={x} key={idx} />
        ))}
      </Alert>
    </div>
  );
}
