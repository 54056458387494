import { Alert } from '@/components/basic';

type UserHealthRestrictedProps = {
  restricted: boolean;
  className: string;
};
export default function UserHealthRestrictedAlert({
  restricted,
  className = ''
}: UserHealthRestrictedProps): JSX.Element {
  if (!restricted) {
    return <></>;
  }

  return (
    <div className={className}>
      <Alert
        variant="tacticAlert"
        canDismiss={false}
        alertTitle={`Reservation's restricted`}
        alertMessage="Your organization's health &amp; safety policies prevent you from making a reservation. Please talk to a health admin."
        className="mt-2 px-4 py-2"
      ></Alert>
    </div>
  );
}
