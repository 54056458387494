import { ReserveModal } from '@/components/reserve/Reserve/ReserveModal';
import useCommonContext from '@/hooks/useCommonContext';
import { useAuthenticated } from '@/lib/api/appUser';
import { TACTIC_HELP_URL } from '@/lib/constants';
import { ResourceType } from '@gettactic/api';
import { Transition } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment, memo, useState } from 'react';
import {
  IoHelpBuoyOutline as Buoy,
  IoCogOutline as Cog,
  IoBarChartOutline as Graph,
  IoHomeOutline as Home,
  IoLogOutOutline as Logout,
  IoMapOutline as MapOutline,
  IoPeopleOutline as People
} from 'react-icons/io5';

type MobileMenuBeta2Props = {
  isMobileMenuOpen: boolean;
  closeMobileMenu: () => void;
  meetingsDisabled: boolean;
  deskBookingDisabled: boolean;
};

export const AppMobileNav = ({
  isMobileMenuOpen,
  closeMobileMenu,
  meetingsDisabled,
  deskBookingDisabled
}: MobileMenuBeta2Props) => {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false);
  const [reserveType, setReserveType] = useState<ResourceType>('desk');
  const { start } = useCommonContext();

  return (
    <Transition
      as={Fragment}
      show={isMobileMenuOpen}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div className="absolute inset-x-0 top-0 z-20 origin-top-right transform shadow-lg transition md:hidden">
        <div className="h-screen divide-y-2 divide-gray-50 bg-white shadow-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2">
          <div className="px-5 pt-5 pb-6">
            <div className="flex items-center justify-between">
              <div className="mx-auto">
                <Link href="/dashboard">
                  <a onClick={closeMobileMenu}>
                    <Image
                      src="https://cdn.gettactic.com/tactic_official_logo_blue.svg"
                      width={165}
                      height={35}
                      alt="Tactic"
                    />
                  </a>
                </Link>
              </div>
              <div className="-mr-2">
                <button
                  onClick={closeMobileMenu}
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-inset"
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-2">
              <nav className="grid grid-cols-1 divide-y-2 divide-gray-50">
                {/* The <Link> components breaks this for forwarding the user to their correct URL -- see desktop link */}
                <a
                  href="/dashboard"
                  className="flex items-center rounded-lg px-2 py-3 hover:bg-gray-50"
                  onClick={closeMobileMenu}
                >
                  <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-secondary text-white">
                    <Home className="h-6 w-6" />
                  </div>
                  <div className="ml-4 font-bold text-base text-gray-800">
                    Dashboard
                  </div>
                </a>
                <Link href="/map">
                  <a
                    className="flex items-center rounded-lg px-2 py-3 hover:bg-gray-50"
                    onClick={closeMobileMenu}
                  >
                    <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-secondary text-white">
                      <MapOutline className="h-6 w-6" />
                    </div>
                    <div className="ml-4 font-bold text-base text-gray-800">
                      Map
                    </div>
                  </a>
                </Link>
                <Link href="/directory">
                  <a
                    className="flex items-center rounded-lg px-2 py-3 hover:bg-gray-50"
                    onClick={closeMobileMenu}
                  >
                    <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-secondary text-white">
                      <People className="h-6 w-6" />
                    </div>
                    <div className="ml-4 font-bold text-base text-gray-800">
                      Directory
                    </div>
                  </a>
                </Link>
                {authenticatedUser?.permissions?.settings ? (
                  <Link href="/reports">
                    <a
                      className="flex items-center rounded-lg px-2 py-3 hover:bg-gray-50"
                      onClick={closeMobileMenu}
                    >
                      <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-secondary text-white">
                        <Graph className="h-6 w-6" />
                      </div>
                      <div className="ml-4 font-bold text-base text-gray-800">
                        Reports
                      </div>
                    </a>
                  </Link>
                ) : null}
                <Link href="/settings/profile">
                  <a
                    className="flex items-center rounded-lg px-2 py-3 hover:bg-gray-50"
                    onClick={closeMobileMenu}
                  >
                    <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-secondary text-white">
                      <Cog className="h-6 w-6" />
                    </div>
                    <div className="ml-4 font-bold text-base text-gray-800">
                      Settings
                    </div>
                  </a>
                </Link>
                <a
                  href={TACTIC_HELP_URL}
                  target="_blank"
                  onClick={closeMobileMenu}
                  className="flex items-center rounded-lg px-2 py-3 hover:bg-gray-50"
                  rel="noreferrer"
                >
                  <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-secondary text-white">
                    <Buoy className="h-6 w-6" />
                  </div>
                  <div className="ml-4 font-bold text-base text-gray-800">
                    Help Center
                  </div>
                </a>
                <a
                  className="flex items-center rounded-lg px-2 py-3 hover:bg-gray-50"
                  onClick={(ev) => {
                    ev.preventDefault();
                    location.href = `/auth/logout?returnUrl=${encodeURIComponent(
                      `${window.location.origin}?logout=true`
                    )}`;
                    closeMobileMenu;
                  }}
                >
                  <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-secondary text-white">
                    <Logout className="h-6 w-6" />
                  </div>
                  <div className="ml-4 font-bold text-base text-gray-800">
                    Logout
                  </div>
                </a>
                <div className="mt-6 flex flex-col space-y-4">
                  {!meetingsDisabled ? (
                    <button
                      className="flex w-full items-center justify-center rounded-md border border-primary bg-white px-4 py-2 font-bold text-base text-primary shadow-sm hover:bg-orange-100"
                      onClick={() => {
                        setReserveType('meeting_room');
                        setIsReserveModalOpen(true);
                      }}
                    >
                      Reserve Room
                    </button>
                  ) : null}
                  {!deskBookingDisabled ? (
                    <button
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-bold text-base text-white shadow-sm hover:bg-orange-700"
                      onClick={() => {
                        setReserveType('desk');
                        setIsReserveModalOpen(true);
                      }}
                    >
                      Reserve Desk
                    </button>
                  ) : null}
                  <ReserveModal
                    reserveModal={isReserveModalOpen}
                    resourceType={reserveType}
                    embedded={false}
                    start={start}
                    onClose={() => setIsReserveModalOpen(!isReserveModalOpen)}
                  />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default memo(AppMobileNav);
