import { api } from '@/lib/api/api';
import { convertTZ } from '@/lib/utils';
import { isValidEmail } from '@/lib/validation/email';
import {
  ApiClientError,
  AuthenticatedUser,
  IResource,
  ISchedule,
  Id,
  Office,
  PRESENCE_STATUS_ACCEPTED,
  PRESENCE_STATUS_PENDING,
  PRESENCE_STATUS_REJECTED,
  PRESENCE_TYPE_IN_PERSON,
  PRESENCE_TYPE_REMOTE,
  Period,
  Resource,
  ScheduleRequest,
  ScheduleRequestUpdate,
  ScheduleSlotRequestUpdate,
  ScheduleVisibility,
  isParking,
  parseDateOnlyString
} from '@gettactic/api';
import { pad } from '@gettactic/helpers/src/numbers/pad';
import { addDays, addMinutes, format, parseISO } from 'date-fns';
import pluralize from 'pluralize';
import { rrulestr } from 'rrule';

/**
 * Text for label & description interpolation
 */
export const RESERVE_TEXTS = {
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],
  hoursPlural: 'hours',
  hoursSingular: 'hour',
  minutesPlural: 'minutes',
  minutesSingular: 'minute',
  title: {
    meeting_room: 'Room Reservation',
    workspace: 'Desk Reservation'
  },
  selectResource: {
    meeting_room: 'Select a room',
    workspace: 'Select a desk',
    parking: 'Select a parking space'
  },
  overCapacityTitle: {
    meeting_room: 'Room Capacity Reached',
    workspace: 'Desk Capacity Reached'
  },
  overCapacityTotalSlots: {
    meeting_room: 'Room Capacity',
    workspace: 'Desk Capacity'
  },
  overCapacityUsersLength: { meeting_room: 'Invited', workspace: 'Invited' },
  selectParking: 'Select parking (optional)',
  noParkingSpace: 'No Parking Space',
  reserve: { meeting_room: 'Reserve Room', workspace: 'Reserve Desk' },
  selectDate: 'Select date',
  selectTime: 'Select starting time',
  selectTimeTitle: 'Time',
  selectDuration: 'Select duration',
  selectTeams: 'Select teams',
  selectStart: 'Select',
  addTitle: 'Add meeting title',
  addDescription: 'Add agenda or notes',
  approvalPresenceOptions: {
    [PRESENCE_STATUS_REJECTED]: 'No',
    [PRESENCE_STATUS_ACCEPTED]: 'Yes',
    [PRESENCE_STATUS_PENDING]: 'Maybe',
    [PRESENCE_TYPE_IN_PERSON]: (resource: string) => `Yes, in ${resource}`,
    [PRESENCE_TYPE_REMOTE]: 'Yes, attending virtually'
  },
  RespondPresenceCount: {
    [PRESENCE_STATUS_PENDING]: (count: number) => `Pending ${count}`,
    [PRESENCE_STATUS_ACCEPTED]: (count: number) => `Yes ${count}`,
    [PRESENCE_STATUS_REJECTED]: (count: number) => `No ${count}`
  }
};

export const noParkingResource: Resource = {
  id: 'none',
  type: 'parking_lot',
  parent_id: null,
  label: RESERVE_TEXTS.noParkingSpace,
  reserved_slots: 0,
  available_slots: 0,
  is_approvable: false,
  total_slots: 0,
  maximum_slots_per_user: 0,
  is_available: false,
  is_open: false,
  has_tags: false,
  is_shareable: false,
  map_url: '',
  name: RESERVE_TEXTS.noParkingSpace,
  description: '',
  office_id: '',
  teams: null,
  users: null,
  weight: 0,
  image_variants: [],
  visible_assignment: null
};

export interface DurationOption {
  label: string;
  value: number;
  durationLabel: string;
}

export function getDurationLabel(durationMinutes: number, short = false) {
  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;
  if (!short) {
    return `${pad(hours)} ${pluralize('Hour', hours)} ${pad(
      minutes
    )} ${pluralize('Minute', minutes)}`;
  }
  return `${pad(hours)}h ${pad(minutes)}m`;
}

export function getDurationOptionFromTime(startTime: Date, filterTime: string) {
  const filterDate = new Date(startTime);
  const filterTimeParts = filterTime.split(':');
  filterDate.setHours(
    Number(filterTimeParts[0]) + (filterTime.includes('pm') ? 12 : 0),
    Number(filterTimeParts[1].replace('am', '').replace('pm', ''))
  );

  if (filterDate <= startTime) {
    // This is future, add a day
    filterDate.setDate(filterDate.getDate() + 1);
  }

  const minutesDifference = Math.floor(
    (filterDate.getTime() - startTime.getTime()) / 60000
  );

  return {
    label: filterTime,
    value: minutesDifference,
    durationLabel: getDurationLabel(minutesDifference, true)
  };
}

export function getDurationOptionFromMinutes(
  startTime: Date,
  minutes: number
): DurationOption {
  const endTime = new Date(startTime.getTime() + minutes * 60 * 1000);
  const endTimeLabel = format(endTime, 'h:mmaaa');
  return {
    value: minutes,
    label: endTimeLabel,
    durationLabel: getDurationLabel(minutes, true)
  };
}

export function getDurationOptions(startTime: Date): DurationOption[] {
  const limitDate = addDays(startTime, 1);
  const durationOptions: DurationOption[] = [];
  for (
    let durationMinutes = 15, hoursLimit = 24, minutesIncrement = 15;
    durationMinutes <= hoursLimit * 60;
    durationMinutes += minutesIncrement
  ) {
    const endTime = new Date(startTime.getTime() + durationMinutes * 60 * 1000);
    if (endTime >= limitDate) {
      // we don't want to go beyond limit time. So we use as last item the time remaining between
      // start time and limit time, so we recalculate durationMinutes
      durationMinutes = Math.floor(
        (limitDate.getTime() - startTime.getTime()) / 1000 / 60
      );
    }
    const durationLabel = getDurationLabel(durationMinutes, true);
    const endTimeLabel = format(endTime, 'h:mmaaa');
    durationOptions.push({
      value: durationMinutes,
      label: endTimeLabel,
      durationLabel
    });
    // we break the loop because we mutated durationMinutes
    if (endTime >= limitDate) {
      break;
    }
  }
  return durationOptions;
}

export type SaveReservationDataUsers = {
  value: string;
}[];

export type SaveReservationData = {
  title?: string;
  time: Date;
  duration: { value: number; label?: string; durationLabel?: string };
  weekDates: Date[];
  resource: IResource;
  parking_resource: IResource | null;
  users: SaveReservationDataUsers;
  teams: { value: string }[];
  organizer: { value: string };
  description?: string;
  visibility?: ScheduleVisibility;
  recurring: string;
};

export function validateReservationsData(data: SaveReservationData) {
  const throwDataError = (msg: string) => {
    throw new Error(
      `Invalid SaveReservationData - Failed validation: [${msg}] - Data: ${JSON.stringify(
        data
      )}`
    );
  };
  if (!data.resource) {
    throwDataError('resource');
  }
  if (!data.duration) {
    throwDataError('duration');
  }
  if (data.duration.value <= 0) {
    throwDataError('duration.value <= 0');
  }
  if (data.weekDates.length < 1) {
    throwDataError('weekDates.length < 1');
  }
  if (!data.time) {
    throwDataError('time');
  }
}

export async function editReservations(
  original: ISchedule,
  data: SaveReservationData,
  editRecurring: boolean,
  dateFormat: string
) {
  // If we are editing a meeting room, then multiple users (attendees) can be included
  // therefore we separate the logic for single-user reservations from multi-user resources.
  // Specifically for a single-user reservation, we only want to update the primary slot ID
  // Where we will need to include the array of slots for meeting rooms.
  const isMeetingRoom = original.resource.type === 'meeting_room';
  const slot = !isMeetingRoom
    ? original.slots.find((x) => !isParking(x.resource))
    : null;
  const parkingSlot = !isMeetingRoom
    ? original.slots.find((x) => isParking(x.resource))
    : null;

  // Pull out the resource information from the form data
  const { resource } = data;

  // Create a list of users from the form data. These should already be cross referenced
  // against existing users in recurring reservation templates & against speficic reservation instances in recurring series.
  const users = [...new Set(data.users.map((x) => x.value))];
  if (!resource) {
    throw new Error('Resource is required');
  }

  // If for some reason the organizer is not included in the list of values,
  // manually add them to the list of users.
  if (isMeetingRoom) {
    if (data.organizer?.value) {
      if (!users.find((x) => x === data.organizer?.value)) {
        users.push(data.organizer.value);
      }
    }
  }

  // Format the start, end and time zone for schedule slots
  const slots: ScheduleSlotRequestUpdate[] = [];
  let dayPart = '';
  let startTimeForRecurring = '';
  let endTimeForRecurring = '';
  data.weekDates.forEach((startDate) => {
    startDate.setHours(data.time.getHours());
    startDate.setMinutes(data.time.getMinutes());
    startDate.setSeconds(0);
    dayPart = format(startDate, 'yyyy-MM-dd');
    const endDate = addMinutes(startDate, data.duration.value);
    const formattedStartDate = `${dayPart}T${format(startDate, 'HH:mm:ss')}.000Z`;
    const period: Period = {
      start: formattedStartDate,
      end: `${format(endDate, 'yyyy-MM-dd')}T${format(endDate, 'HH:mm:ss')}.000Z`,
      time_zone: ''
    };

    if (!startTimeForRecurring) {
      endTimeForRecurring = `${format(endDate, 'HH:mm:ss')}.000Z`;
      startTimeForRecurring = `${format(startDate, 'HH:mm:ss')}.000Z`;
    }

    // For each user in the array, we create an object that will be included in the schedule slots
    users.forEach((user) => {
      const req: ScheduleSlotRequestUpdate = {
        resource_id: resource.id,
        start: period.start,
        end: period.end,
        time_zone: period.time_zone,
        ...getUserSlotIdentifier(user),
        slots: 1
      };

      // This is for reservations that are NOT meeting rooms and are not parking resources.
      if (!isMeetingRoom && slot) {
        req.id = slot.id;
      }

      // If we have existing slots that match the same user ID's provided in the form data,
      // then we should include those slots ID's in the API request. If they are not included,
      // then it's assumed that the user has been removed from the reservation.
      if (isMeetingRoom) {
        req.id = original.slots.find((x) => x.user.id === user)?.id;
      }

      slots.push(req);

      // If the form data parking resource is not null, we need to edit the parking resource
      if (
        data.parking_resource &&
        data.parking_resource.id !== noParkingResource.id
      ) {
        const editedParkingSlot: ScheduleSlotRequestUpdate = {
          resource_id: data.parking_resource.id,
          ...getUserSlotIdentifier(user),
          team_id: null,
          slots: 1,
          start: period.start,
          end: period.end,
          time_zone: period.time_zone
        };
        if (parkingSlot) {
          editedParkingSlot.id = parkingSlot.id;
        }
        slots.push(editedParkingSlot);
      }
    });
  });
  const completelyReplace =
    isMeetingRoom || original.slots.length !== slots.length;
  const organizerId =
    isMeetingRoom && data.organizer?.value ? data.organizer?.value : users[0];
  const scheduleRequest: ScheduleRequestUpdate = {
    schedule_slots: slots,
    title: data.title,
    description: data.description,
    visibility: data.visibility,
    ...getUserOrganizerIdentifier(organizerId)
  };

  if (editRecurring && data.recurring && original?.recurring_task?.id) {
    scheduleRequest.recurring = recurringStringToSaveData(
      data.recurring,
      original.recurring_task.start.split('T')[0],
      startTimeForRecurring,
      endTimeForRecurring
    );
    scheduleRequest.recurring.recurring_task_id = original.recurring_task.id;
  }

  // Always use PUT for meeting rooms
  try {
    completelyReplace
      ? await api.client.schedules.put(
          editRecurring && original.recurring_schedule_id
            ? original.recurring_schedule_id
            : original.id,
          scheduleRequest
        )
      : await api.client.schedules.patch(
          editRecurring && original.recurring_schedule_id
            ? original.recurring_schedule_id
            : original.id,
          scheduleRequest
        );
  } catch (e) {
    if (e instanceof ApiClientError) {
      const whenError = parseValidation(
        e.response.result,
        dayPart,
        true,
        'edit',
        dateFormat
      );
      console.log(
        'whenError',
        whenError,
        'e.response.result',
        e.response.result,
        'dayPart',
        dayPart
      );
      return {
        success: false,
        message: whenError.error,
        ...whenError
      };
    }
    return {
      success: false,
      hasError: true,
      message: 'Unknown error'
    };
  }
  return {
    success: true,
    hasError: false,
    message: ''
  };
}

function parseValidation(
  validation:
    | Id
    | {
        context:
          | { daysOpen: number[] }
          | { dates: { [day: string]: string[] } };
      }
    | { errors: { slots: string; schedule_slots: string } }
    | null,
  dayPart: string,
  hasError: boolean,
  type: 'edit' | 'create',
  dateFormat: string
) {
  const hasContext =
    validation && 'context' in validation && validation.context;
  const context = hasContext ? validation.context : null;
  const dates = context && 'dates' in context ? context.dates : {};
  const datesWithError = Object.keys(dates).filter((x) => dates[x].length > 0);
  const datesFormatted = datesWithError.map((x) =>
    format(parseDateOnlyString(x), dateFormat)
  );
  const formattedDayPart = format(parseDateOnlyString(dayPart), dateFormat);
  if (datesWithError.length > 0) {
    return {
      error:
        "We couldn't reserve in the following dates: " +
        datesFormatted.join(', '),
      hasError,
      start: datesFormatted[0]
    };
  } else if (
    hasContext &&
    'daysOpen' in validation.context &&
    validation.context.daysOpen.length > 0
  ) {
    const days = [];
    for (const dayOfWeek of validation.context.daysOpen) {
      days.push(RESERVE_TEXTS.days[dayOfWeek]);
    }
    return {
      error:
        'The office is open only on the following days: ' + days.join(', '),
      hasError,
      start: formattedDayPart
    };
  } else if (
    validation &&
    'errors' in validation &&
    validation.errors &&
    validation.errors.slots &&
    validation.errors.slots === 'range'
  ) {
    return {
      error: "There's no free capacity",
      hasError,
      start: formattedDayPart
    };
  } else if (
    validation &&
    'errors' in validation &&
    validation.errors &&
    validation.errors.schedule_slots &&
    validation.errors.schedule_slots === 'limit'
  ) {
    return {
      error:
        'You aren’t allowed to reserve two desks at the same time. Please select a date & time when you do not have an existing reservation.',
      hasError
    };
  } else {
    return {
      error:
        type === 'create'
          ? 'There was an issue trying to reserve'
          : 'There was an issue editing the reservation',
      hasError,
      start: formattedDayPart
    };
  }
}

function recurringStringToSaveData(recurring: string, formattedStartDate: string, startTime: string, endTime: string) {
  const rule = rrulestr(recurring);
  const recurringWithoutDates = recurring
    .replaceAll(/DTSTART:(\d+)T(\d+)Z?/g, '')
    .replaceAll(/UNTIL=(\d+)T(\d+)Z?;?/g, '')
    .replaceAll(/RRULE:/g, '')
    .trim()
    .replaceAll(/\;$/g, '');
  const saveObject: {
    rrules: string[];
    start?: string;
    end?: string;
    recurring_task_id?: string;
  } = {
    rrules: [recurringWithoutDates]
  };

  saveObject.start = `${formattedStartDate}T${startTime}`;

  if (rule.options.until) {
    saveObject.end = `${format(rule.options.until, 'yyyy-MM-dd')}T${endTime}`;
  }
  return saveObject;
}

export function recurringToText(schedule: ISchedule): string {
  if (!schedule.recurring_task) {
    return '';
  }
  const rrule = recurringToRRule(schedule);

  if (!rrule) {
    return '';
  }

  return rrule.toText();
}

function getUserSlotIdentifier(userIdOrEmail?: string | null) {
  const validEmail = isValidEmail(userIdOrEmail);
  return {
    user_id: validEmail ? null : userIdOrEmail ?? null,
    email: validEmail ? userIdOrEmail : null
  };
}

function getUserOrganizerIdentifier(userIdOrEmail?: string | null) {
  const validEmail = isValidEmail(userIdOrEmail);
  return {
    organizer_id: validEmail ? null : userIdOrEmail ?? null,
    organizer_email: validEmail ? userIdOrEmail : null
  };
}

export function recurringToRRule(schedule: ISchedule) {
  if (!schedule.recurring_task) {
    return '';
  }
  // rrule needs all dates in UTC
  const readDate = (date: string) =>
    convertTZ(parseISO(date.replace('Z', '')), 'UTC');
  const options = {
    dtstart: schedule.recurring_task.start
      ? readDate(schedule.recurring_task.start)
      : undefined
  };
  if (!schedule.recurring_task.start) {
    delete options.dtstart;
  }
  let strRules = schedule.recurring_task.rrules.join('');
  if (schedule.recurring_task.end) {
    strRules += `;UNTIL=${format(readDate(schedule.recurring_task.end), "yyyyMMdd'T'HHmmss")}`;
  }
  const rrule = rrulestr(strRules, options);
  return rrule;
}

export async function saveReservations(
  data: SaveReservationData,
  options: {
    isMeetingRoom: boolean;
    officeId: string;
    allowOthers: boolean;
    authUserId: string;
    organizerId: string | null;
    timeZone: string;
    office?: Office;
    noParkingResource?: Resource;
    dateFormat: string;
  }
): Promise<{ hasError: boolean; message?: string; success: number }> {
  validateReservationsData(data);
  const { resource } = data;
  if (!resource) {
    throw new Error('Undefined resource id');
  }

  const users: string[] = [];
  let organizerId: null | string = null;
  if (options.isMeetingRoom) {
    users.push(...data.users.map((x) => x.value));
    if (!options.allowOthers) {
      // when is not on behalf we add the current user
      if (!users.includes(options.authUserId)) {
        users.push(options.authUserId);
      }
    } else if (options.organizerId) {
      organizerId = options.organizerId;
      if (!users.includes(organizerId)) {
        users.push(organizerId);
      }
    }
  } else {
    // For desks, we want only One user
    const userForDesk = options.allowOthers
      ? data.users.length
        ? data.users[0].value
        : null
      : options.authUserId;
    organizerId = options.allowOthers ? userForDesk : null;
    if (userForDesk) {
      users.push(userForDesk);
    }
  }

  if (users.length <= 0) {
    return {
      hasError: true,
      message: `You need to select at least one guest or organizer`,
      success: 0
    };
  }
  let firstStartDateForRecurring = '';
  let startTimeForRecurring = '';
  let endTimeForRecurring = '';
  const promises = data.weekDates.map(async (startDate) => {
    startDate.setHours(data.time.getHours());
    startDate.setMinutes(data.time.getMinutes());
    startDate.setSeconds(0);
    const dayPart = format(startDate, 'yyyy-MM-dd');
    const endDate = addMinutes(startDate, data.duration.value);
    const startFormatted = `${dayPart}T${format(startDate, 'HH:mm:ss')}.000Z`;
    if (!firstStartDateForRecurring) {
      firstStartDateForRecurring = dayPart;
      endTimeForRecurring = `${format(endDate, 'HH:mm:ss')}.000Z`;
      startTimeForRecurring = `${format(startDate, 'HH:mm:ss')}.000Z`;
    }

    const period: Period = {
      start: startFormatted,
      end: `${format(endDate, 'yyyy-MM-dd')}T${format(endDate, 'HH:mm:ss')}.000Z`,
      time_zone: options.timeZone
    };

    let scheduleRequest: ScheduleRequest = {
      schedule_slots: []
    };
    if (options.allowOthers && organizerId) {
      scheduleRequest = {
        ...scheduleRequest,
        ...getUserOrganizerIdentifier(organizerId)
      };
    }

    const { title, description, visibility } = data;

    if (title) scheduleRequest.title = title;
    if (description) scheduleRequest.description = description;
    if (visibility) scheduleRequest.visibility = visibility;

    users.forEach((userId) => {
      scheduleRequest.schedule_slots.push({
        ...getUserSlotIdentifier(userId),
        resource_id: resource.id,
        team_id: null,
        slots: 1,
        start: period.start,
        end: period.end,
        time_zone: period.time_zone
      });
    });

    const teams = data.teams
      ? data.teams.map((currentTeam: { value: string }) => currentTeam.value)
      : [];

    teams.forEach((teamId) => {
      scheduleRequest.schedule_slots.push({
        resource_id: resource.id,
        user_id: null,
        team_id: teamId,
        slots: 1,
        start: period.start,
        end: period.end,
        time_zone: period.time_zone
      });
    });

    const parkingResourceId =
      options.noParkingResource &&
      data.parking_resource &&
      data.parking_resource &&
      data.parking_resource.id !== options.noParkingResource.id
        ? data.parking_resource.id
        : null;

    if (parkingResourceId !== null && users.length > 0) {
      scheduleRequest.schedule_slots.push({
        resource_id: parkingResourceId,
        ...getUserSlotIdentifier(users[0]),
        team_id: null,
        slots: 1,
        start: period.start,
        end: period.end,
        time_zone: period.time_zone
      });
    }

    if (data.recurring && data.recurring !== 'no') {
      scheduleRequest.recurring = recurringStringToSaveData(
        data.recurring,
        firstStartDateForRecurring,
        startTimeForRecurring,
        endTimeForRecurring
      );
    }

    try {
      await api.client.schedules.create(options.officeId, scheduleRequest);
      return parseValidation(
        null,
        dayPart,
        false,
        'create',
        options.dateFormat
      );
    } catch (e) {
      if (e instanceof ApiClientError) {
        return parseValidation(
          e.response?.result ?? null,
          dayPart,
          true,
          'create',
          options.dateFormat
        );
      }
      return parseValidation(null, dayPart, true, 'create', options.dateFormat);
    }
  });

  const results = await Promise.all(promises);

  const success = results.filter((x) => !x.hasError);
  const errors = results.filter((x) => x.hasError) as {
    error: string;
    start: string;
  }[];
  const hasError = errors.length > 0;
  const grouping: { [idx: string]: string[] } = {};
  errors.forEach((err) => {
    grouping[err.error] = grouping[err.error] || [];
    if (err.start) grouping[err.error].push(err.start);
  });

  let message =
    !hasError && success.length === 1
      ? 'Your reservation was made'
      : 'All your reservations were made';
  if (errors.length > 0) {
    message = `We couldn't complete all your reservations:` + '\n';
    for (const error of Object.keys(grouping)) {
      message +=
        `${error} ${
          grouping[error].length > 0
            ? grouping[error].length === 1
              ? 'for day'
              : 'for days'
            : ''
        } ${grouping[error].join(', ')}` + '\n';
    }
  }
  return {
    success: success.length,
    hasError,
    message
  };
}

/**
 * Tells you whether the user should be restricted from making reservations based on a Tactic Health
 * user status. The only time a user should be restricted is if the organization has health_setting's turned
 * on in that office & the user's health status is anything besides clear (with or without an end date)
 *
 * @param authenticatedUser
 * @returns boolean
 */
export function getOfficeHealthReservationRestrictions(
  authenticatedUser: AuthenticatedUser,
  office?: Office
) {
  return !!(
    office &&
    office.health_setting !== null &&
    authenticatedUser.user?.organization_user.health?.status !== 'clear'
  );
}

export function getReserveCardKey(schedule: ISchedule) {
  return `res-${schedule.id}${schedule.slots.map((x) => x.id).join('-')}`;
}

export function findCheckinTargetSlot(
  schedule: ISchedule,
  targetUserId: string
) {
  const slotsForUser = schedule.slots.filter((x) => x.user.id === targetUserId);
  return slotsForUser.find((x) => x.resource.id === schedule.resource.id);
}
