import { Office } from '@gettactic/api';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import React from 'react';
import { Flex } from '../../../primitives';

export interface OfficeLabelProps {
  office: Office | undefined | null;
}

export const OfficeLabel: React.FC<OfficeLabelProps> = ({ office }) => {
  if (!office) return null;
  return (
    <Flex className={'gap-2'}>
      <OfficeBuildingIcon className="h-5 w-5 text-secondary" />
      {office.name}
    </Flex>
  );
};
