import * as yup from 'yup';
import { ALL_WEEK_DAYS, PostOperationalHours } from '@gettactic/api';

// keep in sync with server values
export const DEFAULT_WEIGHT_RULE = 10;

const emptyToNull = (curr: string | null, orig: string | null) =>
  orig === '' ? null : curr;

function validateMinDate(startName: string, afterName: string) {
  return (start: Date | undefined) => {
    if (start && !isNaN(start as unknown as number)) {
      return yup
        .date()
        .nullable()
        .transform(emptyToNull)
        .min(
          new Date(start.getTime() - 1),
          `${startName} must be before ${afterName}`
        );
    }
    return yup.date().transform(emptyToNull).nullable();
  };
}

function validateMinHour(startName: string, afterName: string) {
  return (start: Date | undefined) => {
    if (start && !isNaN(start as unknown as number)) {
      return yup
        .date()
        .transform(emptyToNull)
        .min(
          new Date(start.getTime() - 1),
          `${startName} must be before ${afterName}`
        );
    }
    return yup.date().transform(emptyToNull).nullable();
  };
}

const shapeRule = {
  name: yup.string().min(1).defined(),
  start_time: yup.date().optional(),
  end_time: yup
    .date()
    .optional()
    .when('start_time', validateMinHour('Start Time', 'End Time')),
  since: yup.date().optional(),
  until: yup
    .date()
    .optional()
    .when('since', validateMinDate('Start Date', 'End Date')),
  is_available: yup.boolean().optional(),
  days: yup.array().of(yup.string().oneOf([...ALL_WEEK_DAYS]))
};
const shapeHoliday = {
  start_time: yup.date().optional(),
  end_time: yup
    .date()
    .when('start_time', validateMinDate('Start Time', 'End Time')),
  since: yup.date(),
  until: yup.date().when('since', validateMinDate('Start Date', 'End Date')),
  name: yup.string().min(1).defined()
};

export const shapeOperationalHours = {};

export type OperationalHoursFormType = PostOperationalHours;

export const isValid12HourTimeFormat = (time: string) => {
  const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9][aApP][mM]$/;
  return regex.test(time);
};

ALL_WEEK_DAYS.forEach(
  (day) =>
    (shapeOperationalHours[day] = yup.object().shape({
      id: yup.string(),
      is_available: yup.boolean(),
      start_time: yup.date().optional(),
      end_time: yup
        .date()
        .optional()
        .when('start_time', validateMinHour('Start Time', 'End Time'))
    }))
);
export const schemaOperationalHour = yup.object().shape(shapeOperationalHours);
export const schemaHourRule = yup.object().shape(shapeRule);
export const schemaHourHoliday = yup.object().shape(shapeHoliday);

export const schemaOperationalHours = yup
  .object()
  .shape({ days: yup.object({ ...shapeOperationalHours }) });
