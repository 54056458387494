import { Drawer, ScrollArea } from '@mantine/core';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { INewsItem } from '@gettactic/api';
import { TextEditorContent } from '@/components/basic';

type NotificationDrawerProps = {
  openSidePanel: boolean;
  setOpenSidePanel: React.Dispatch<boolean>;
  newsData: INewsItem[];
};

const ThumbTackIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
    >
      <path d="M 14.490234 2.4902344 A 1.0001 1.0001 0 0 0 13.792969 4.2070312 L 14.388672 4.8027344 L 8.1894531 9.7753906 L 7.2070312 8.7929688 A 1.0001 1.0001 0 0 0 6.4902344 8.4902344 A 1.0001 1.0001 0 0 0 5.7929688 10.207031 L 9.09375 13.507812 L 3.2890625 19.310547 C 2.9020625 19.697547 2.9020625 20.323937 3.2890625 20.710938 C 3.6760625 21.097938 4.3024531 21.097938 4.6894531 20.710938 L 10.492188 14.90625 L 13.792969 18.207031 A 1.0001 1.0001 0 1 0 15.207031 16.792969 L 14.310547 15.896484 L 19.214844 9.6289062 L 19.792969 10.207031 A 1.0001 1.0001 0 1 0 21.207031 8.7929688 L 15.207031 2.7929688 A 1.0001 1.0001 0 0 0 14.490234 2.4902344 z" />
    </svg>
  );
};

const OfficeNewsCard = ({ news }: { news: INewsItem }) => {
  return (
    <li className="py-5 first:pt-0">
      <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-9">
            <h3 className="text-xl font-bold text-gray-800">
              {news.is_pinned ? (
                <ThumbTackIcon className="inline-block h-5 w-5 text-gray-800" />
              ) : null}{' '}
              {news.title}
            </h3>
          </div>
          <div className="col-span-3">
            {!news.office_id ? (
              <h4 className="pr-2 text-right text-sm text-gray-500">
                All Offices
              </h4>
            ) : null}
          </div>
        </div>
        <h4 className="text-sm text-gray-500">
          Last Updated: {format(parseISO(news.updated), 'PP')}
        </h4>
        <div className="mt-2 text-sm text-gray-800">
          <TextEditorContent value={news.body} />
        </div>
      </div>
    </li>
  );
};

export const NotificationDrawer = ({
  openSidePanel,
  setOpenSidePanel,
  newsData
}: NotificationDrawerProps): JSX.Element => {
  return (
    <Drawer
      opened={openSidePanel}
      onClose={() => setOpenSidePanel(false)}
      size="lg"
      title="Notifications"
      position="right"
      overlayProps={{
        color: 'transparent',
        opacity: 0,
        blur: 0
      }}
      classNames={{
        inner: 'md:top-[65px] max-w-full md:pl-2',
        title:
          'text-xl font-bold leading-relaxed text-gray-900 sm:text-2xl sm:leading-snug',
        header: 'px-4 py-2'
      }}
    >
      <div className="relative mt-6 flex-1">
        <div className="bg-white">
          {newsData?.length > 0 ? (
            <div className="-my-5 bg-white pb-5 pl-2">
              <ScrollArea
                style={{ height: 'calc(100vh - 170px)' }}
                offsetScrollbars
                className="h-full"
              >
                <ul className="divide-y divide-gray-200">
                  {newsData.map((news) => (
                    <OfficeNewsCard key={news.id} news={news} />
                  ))}
                </ul>
              </ScrollArea>
            </div>
          ) : (
            <div className="flex flex-col justify-center p-6">
              <h3 className="text-lg font-bold text-gray-800">No news yet!</h3>
              <p className="mt-2 text-gray-800">
                We'll keep you in the loop once an admin on your account
                publishes office news.
              </p>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};
