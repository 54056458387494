import { FormProps } from 'react-hook-form';
import { TextEditor } from '@/components/basic';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '@/components/ui/form';

type ReserveDescriptionProps = {
  form: FormProps<any>;
};

export default function ReserveFieldDescription({
  form
}: ReserveDescriptionProps) {
  return (
    <>
      <FormField
        control={form.control}
        name="description"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <TextEditor onChange={field.onChange} value={field.value} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
