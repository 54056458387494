import { Division, Office } from '@gettactic/api';
import { createContext } from '@gettactic/helpers/src/createContext';

export interface DivisionOfficeSelectorState {
  selectedDivision?: Division | null;
  selectedOffice?: Office | null;
  onSelectDivision?: (division?: Division) => void;
  onSelectOffice?: (office?: Office) => void;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  hasAllItem?: boolean;
}

const [DivisionOfficeSelectorProvider, useDivisionOfficeSelector] =
  createContext<DivisionOfficeSelectorState>({
    hookName: 'useDivisionOfficeSelector',
    providerName: 'DivisionOfficeSelectorProvider'
  });

export { DivisionOfficeSelectorProvider, useDivisionOfficeSelector };
