import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { DivisionsTree } from '@gettactic/api';
import { OfficeLabel } from '@gettactic/components/src/data-display/labels/OfficeLabel';
import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';
import { useDivisionOfficeSelector } from './helpers';

export interface DivisionOfficeRenderProps {
  tree: DivisionsTree;
  allowNoOptions?: boolean;
}

export const DivisionOfficeRender: React.FC<DivisionOfficeRenderProps> = ({
  tree,
  allowNoOptions
}) => {
  const { selectedOffice, onSelectOffice } = useDivisionOfficeSelector();

  if (allowNoOptions && !tree.offices.length) {
    return <DropdownMenuItem disabled>No Offices/Divisions</DropdownMenuItem>;
  }

  return (
    <>
      {tree.offices.map((office) => (
        <DropdownMenuItem
          key={office.id}
          onClick={() => {
            onSelectOffice?.(office);
          }}
        >
          <OfficeLabel office={office} />
          {selectedOffice?.id === office.id ? (
            <CheckIcon className="ml-auto h-5 w-5 text-primary" />
          ) : null}
        </DropdownMenuItem>
      ))}
    </>
  );
};
