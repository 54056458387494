import React, { useMemo } from 'react';
import Select from 'react-select';

type Props = {
  floor: string | null;
  setFloor: React.Dispatch<React.SetStateAction<string | null>>;
  floors: { id: string | null; name: string }[];
  className?: string;
  classNamePrefix?: string;
  readonly?: boolean;
};

export default function FloorFilterDropdown({
  floors,
  setFloor,
  floor,
  className = '',
  classNamePrefix = 'react-select',
  readonly = false
}: Props) {
  const defaultFloor = {
    value: null,
    label: `-- All floors --`
  };
  const options = useMemo(() => {
    const floorValues = floors.map((x) => ({ value: x.id, label: x.name }));
    floorValues.unshift(defaultFloor);
    return floorValues;
  }, [floors]);
  const value = options.find((x) => x.value === floor);
  return (
    <Select
      isDisabled={readonly}
      className={className}
      onChange={(d, event) => {
        if (readonly) {
          return;
        }
        setFloor(d?.value ?? null);
      }}
      value={typeof value === 'undefined' ? defaultFloor : value}
      options={options}
      classNamePrefix={classNamePrefix}
    />
  );
}
