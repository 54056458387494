import { useForm } from '@formspree/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button, Modal } from '@/components/basic';
import LoadingSpinner from '@/components/util/LoadingSpinner';

type FeedbackModalProps = {
  open: boolean;
  close: () => void;
  email?: string;
  organizationSlug: string;
  officeId: string;
  officeName: string;
  userName: string;
  facilityTicketsEnabled: boolean;
};

export function FeedbackModal({
  open,
  close,
  email,
  organizationSlug,
  officeId,
  officeName,
  userName,
  facilityTicketsEnabled = false
}: FeedbackModalProps) {
  const router = useRouter();
  const [state, handleSubmit] = useForm(
    facilityTicketsEnabled ? 'xvoyopbr' : 'xnqlyrgg'
  );

  useEffect(() => {
    if (!state.succeeded) {
      return;
    }
    toast.success('Feedback submitted');
    close();
  }, [state]);

  return (
    <>
      <Modal
        title={
          facilityTicketsEnabled ? 'Create Facility Ticket' : 'Share Feedback'
        }
        opened={open}
        onClose={close}
        size="md"
      >
        <form onSubmit={handleSubmit}>
          <div>
            <div className="mt-4 grid grid-cols-6">
              <div className="col-span-6">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="hidden"
                  defaultValue={email}
                />
              </div>
              <div className="col-span-6">
                <div className="mt-1">
                  {facilityTicketsEnabled ? (
                    <>
                      <input
                        type="text"
                        name="orgSlug"
                        id="orgSlug"
                        className="hidden"
                        defaultValue={organizationSlug}
                      />
                      <input
                        type="text"
                        name="formName"
                        id="formName"
                        className="hidden"
                        defaultValue={'facilities_ticket_created'}
                      />
                      <input
                        type="text"
                        name="officeId"
                        id="officeId"
                        className="hidden"
                        defaultValue={officeId}
                      />
                      <input
                        type="text"
                        name="officeName"
                        id="officeName"
                        className="hidden"
                        defaultValue={officeName}
                      />
                      <input
                        type="text"
                        name="userName"
                        id="userName"
                        className="hidden"
                        defaultValue={userName}
                      />
                      <label
                        htmlFor="message"
                        className="block font-medium text-gray-800"
                      >
                        Details
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        required
                        placeholder="Explain the issue you are experiencing"
                        rows={3}
                        className="block w-full rounded-md border border-gray-300 px-2 py-1 placeholder-gray-600 shadow-sm focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-primary"
                        defaultValue={''}
                      />
                    </>
                  ) : (
                    <>
                      <label
                        htmlFor="feedback"
                        className="block font-medium text-gray-800"
                      >
                        Details
                      </label>
                      <textarea
                        id="feedback"
                        name="feedback"
                        required
                        placeholder="Share feedback to help make Tactic better..."
                        rows={3}
                        className="block w-full rounded-md border border-gray-300 px-2 py-1 placeholder-gray-600 shadow-sm focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-primary"
                        defaultValue={''}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <input
              className="hidden"
              id="url"
              name="url"
              defaultValue={router.asPath}
            />
          </div>
          <Modal.Footer>
            <div className="mt-4">
              <Button
                type="submit"
                disabled={state.submitting}
                variant="primary"
                layout="squared"
              >
                {state.submitting ? (
                  <LoadingSpinner />
                ) : facilityTicketsEnabled ? (
                  'Create Ticket'
                ) : (
                  'Submit Feedback'
                )}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
