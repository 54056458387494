import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { ResourceType } from '@gettactic/api';
import { api } from '@/lib/api/api';

type UseResKey = {
  officeId: string;
  durationMins: number;
  weekDatesStr: string[];
  resourceType: ResourceType;
  startTime: Date;
  filterParent?: string | null;
  organizer?: string | null;
};

export default function useReserveResources({
  officeId,
  durationMins,
  weekDatesStr,
  resourceType,
  startTime,
  filterParent,
  organizer = null
}: UseResKey) {
  function getEntities(entities: ResourceType) {
    const types: ResourceType[] =
      entities === 'desk' || entities === 'workspace'
        ? ['desk', 'workspace']
        : entities === 'parking_space' || entities === 'parking_lot'
          ? ['parking_lot', 'parking_space']
          : ['meeting_room'];
    return types;
  }

  const { data: office } = useQuery(
    ['organizations.offices', officeId],
    async () => {
      const res = await api.client.organizations.office(officeId!);
      return res.result;
    },
    {
      enabled: !!officeId
    }
  );

  const { data: resourcesList } = useQuery(
    [
      'organizations.schedulable',
      officeId,
      weekDatesStr,
      resourceType,
      filterParent,
      startTime,
      durationMins,
      organizer
    ],
    async () => {
      const startFormatted = format(startTime, 'HH:mm:ss');
      const dates = weekDatesStr.map((w) => {
        return `${w}T${startFormatted}.000Z`;
      });
      const res = await api.client.organizations.schedulable(
        officeId!,
        getEntities(resourceType),
        filterParent,
        dates,
        durationMins.toString(),
        null,
        undefined,
        !!filterParent,
        false,
        organizer
      );
      return res.result;
    },
    { enabled: !!officeId }
  );

  const { data: parkingResourcesList } = useQuery(
    [
      'organizations.schedulable',
      officeId,
      weekDatesStr,
      getEntities('parking_space'),
      startTime,
      durationMins,
      organizer
    ],
    async () => {
      const startFormatted = format(startTime, 'HH:mm:ss');
      const dates = weekDatesStr.map((w) => {
        return `${w}T${startFormatted}.000Z`;
      });
      const res = await api.client.organizations.schedulable(
        officeId!,
        getEntities('parking_space'),
        null,
        dates,
        durationMins.toString(),
        null,
        undefined,
        undefined,
        false,
        organizer
      );
      return res.result;
    },
    {
      enabled:
        !!officeId &&
        !!office &&
        office.total_parking_spaces + office.total_parking_lots > 0
    }
  );

  return {
    resourcesList,
    office,
    parkingResourcesList
  };
}
