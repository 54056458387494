import React, { ReactNode } from 'react';

interface ICommonContext {
  start?: Date;
  setCommon: React.Dispatch<React.SetStateAction<ICommonContextStates | null>>;
}

export interface ICommonContextStates {
  start: Date;
}
const CommonContext = React.createContext<ICommonContext | null>(null);
/**
 *
 * @abbr op : Operation
 *
 */
const CommonProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [common, setCommon] = React.useState<ICommonContextStates | null>(null);

  return (
    <CommonContext.Provider
      value={{
        ...common,
        setCommon
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export { CommonProvider, CommonContext };
