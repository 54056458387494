import { DivisionsTree } from '@gettactic/api';

export function findDivisionPathByOfficeId(
  divisionsTree: DivisionsTree[],
  officeId: string
): string[] | null {
  const findPath = (
    tree: DivisionsTree[],
    targetOfficeId: string,
    path: string[] = []
  ): string[] | null => {
    for (const division of tree) {
      const newPath = [...path, division.id];

      if (division.offices.some((office) => office.id === targetOfficeId)) {
        return newPath;
      }

      const childPath =
        division.children?.length > 0
          ? findPath(division.children, targetOfficeId, newPath)
          : null;
      if (childPath) {
        return childPath;
      }
    }

    return null;
  };

  return findPath(divisionsTree, officeId);
}
