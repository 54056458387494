import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

interface IIconButton {
  onPress: () => void;
  Icon: any;
  label: string;
}

const IconButton = ({
  onPress,
  Icon,
  label,
  ...props
}: IIconButton &
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >) => {
  return (
    <button
      onClick={onPress}
      className="ml-8 mr-1 rounded-full p-1 text-tertiary hover:text-tertiary-hover focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-primary"
      {...props}
    >
      <span className="sr-only">{label}</span>
      <Icon className="h-7 w-7" aria-hidden="true" />
    </button>
  );
};

export default IconButton;
