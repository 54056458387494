import * as Icons from 'lucide-react';
import React from 'react';
import { LucideIconProps } from './LucideIcon.types';

export const LucideIcon = React.forwardRef<SVGSVGElement, LucideIconProps>(
  (props, ref) => {
    const { name, color, size = 6, className } = props;
    if (!name) return null;
    const LucideIconComponent = Icons[`${name}Icon`];
    if (!LucideIcon) {
      throw new Error(`Icon ${name} not found`);
    }
    return (
      <LucideIconComponent
        color={color}
        size={size}
        className={className}
        width={size ? `${size * 4}px` : undefined}
        height={size ? `${size * 4}px` : undefined}
        ref={ref}
      />
    );
  }
);

LucideIcon.displayName = 'Icon';
