// add a current property based on router.pathname for each MenuItem
import { NextRouter } from 'next/router';

export function addCurrent<
  GenericMenuItem extends { href: string; startsWith?: string }
>(
  menu: GenericMenuItem[],
  router: NextRouter
): (GenericMenuItem & { current: boolean })[] {
  return menu.map((x) => ({
    ...x,
    current: x.startsWith
      ? router.pathname.startsWith(x.startsWith) ||
        router.asPath.startsWith(x.startsWith)
      : router.pathname === x.href || router.asPath === x.href
  }));
}
