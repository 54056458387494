import { useMaskInput } from '@/hooks/useInputMask';
import { DurationOption, getDurationOptionFromTime } from '@/lib/utils-reserve';
import { isValid12HourTimeFormat } from '@/lib/validation/hour';

export const loadDurationOptions = (
  filterValue: string,
  startTime: Date,
  durationOptions: DurationOption[]
) => {
  const options = [...durationOptions];
  const filter =
    filterValue.length === 3
      ? `${filterValue}00am`
      : filterValue.length === 5
        ? `${filterValue}am`
        : filterValue;
  if (
    filter &&
    isValid12HourTimeFormat(filter) &&
    !options.find((option) => option.label === filter)
  ) {
    const filterDurationOption = getDurationOptionFromTime(startTime, filter);

    if (filterDurationOption) {
      options.push(filterDurationOption);
    }
  }

  if (!filter) return options;
  return options.filter((option) => {
    return option.label.toLowerCase().includes(filter.toLowerCase());
  });
};

export const useReserveDurationInputMask = () => {
  return useMaskInput('99:99Hm', {
    customPatternCharacterRegexps: {
      H: /[aApP]/
    },
    renderValue: (value) => {
      const hours = value.slice(0, 2);
      const minutes = value.slice(3, 5);
      const separator = value.slice(2, 3);
      const ampm = String(value.slice(5)).toLowerCase();

      return `${hours && Number(hours) > 12 ? 12 : hours}${separator}${
        minutes && Number(minutes) > 59 ? 59 : minutes
      }${
        ampm && ampm.length === 2 && ampm !== 'am' && ampm !== 'pm'
          ? 'am'
          : ampm
      }`;
    }
  });
};
