import { AuthenticatedUser } from '@gettactic/api';

export function canRespondOthers(authenticatedUser: AuthenticatedUser) {
  return !!authenticatedUser?.user?.permissions.find(
    (x) => x === 'schedule:respond:manage' || x === 'schedule:respond'
  );
}

export function canScheduleOthers(authenticatedUser: AuthenticatedUser) {
  return (
    !!authenticatedUser?.permissions?.schedule_others ||
    !!authenticatedUser?.permissions?.schedule_others_managed
  );
}
