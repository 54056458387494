import { LoadingSpinner } from '@/components/util/LoadingSpinner';
import { RESERVE_TEXTS } from '@/lib/utils-reserve';
import { SaveReservationData } from '@/lib/utils-reserve';
import { Resource } from '@gettactic/api';
import { useMemo } from 'react';
import { Controller, FieldErrors, UseFormReturn } from 'react-hook-form';
import Select, { components } from 'react-select';

type ReserveFieldParkingListProps = {
  form: UseFormReturn<SaveReservationData>;
  parkingResourcesList: Resource[] | null | undefined;
  defaultValue: Resource | null;
  noParkingResource?: Resource;
  errors: FieldErrors<SaveReservationData>;
};

export default function ReserveFieldParkingList({
  parkingResourcesList,
  form,
  defaultValue: def,
  noParkingResource,
  errors
}: ReserveFieldParkingListProps) {
  const parkingList = useMemo(() => {
    if (!parkingResourcesList) {
      return null;
    }
    if (noParkingResource) {
      return parkingResourcesList.concat(noParkingResource);
    }
    return parkingResourcesList;
  }, [noParkingResource, parkingResourcesList]);

  const options = useMemo(
    () =>
      (parkingList ?? []).map((res) => ({
        ...res,
        label: res.name,
        value: res.id
      })),
    [parkingList]
  );
  const defaultValue = useMemo(
    () => options.find((res) => res.id === def?.id),
    [def, options]
  );

  if (!parkingList) {
    return <LoadingSpinner color="text-secondary" />;
  }

  return (
    <>
      <Controller
        control={form.control}
        name={'parking_resource'}
        rules={{ required: true }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={onChange}
            value={value}
            placeholder={RESERVE_TEXTS.selectParking}
            className={'resource-select'}
            classNamePrefix="react-select"
            isOptionDisabled={(option) => option.type === 'area'}
            noOptionsMessage={() => 'No availability'}
            components={{
              GroupHeading: (props) => (
                <div className="mx-2 h-8 border-gray-50 border-b-2 pl-2 font-bold">
                  {props.data.label}
                </div>
              ),
              Option: (props) => (
                <components.Option {...props}>
                  {props.data.name}
                  {props.data.available_slots > 0 && (
                    <span className="ml-2 rounded-full bg-green-100 px-2.5 py-0.5 font-medium text-green-800 text-xs">
                      {props.data.available_slots}
                    </span>
                  )}
                </components.Option>
              )
            }}
            options={options}
          />
        )}
      />
      {errors?.parking_resource &&
        errors.parking_resource.type === 'required' && (
          <p className="text-primary">{RESERVE_TEXTS.selectParking}</p>
        )}
    </>
  );
}
