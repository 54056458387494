import React, { useMemo } from 'react';
import Select from 'react-select';

type Props = {
  officeId: string | null;
  setOfficeId: React.Dispatch<React.SetStateAction<string>>;
  offices: { id: string | null; name: string }[];
  className?: string;
  classNamePrefix?: string;
  readonly?: boolean;
};

export default function OfficeFilterDropdown({
  offices,
  setOfficeId,
  officeId,
  className = '',
  classNamePrefix = 'react-select',
  readonly = false
}: Props) {
  const options = useMemo(() => {
    const officeValues = offices.map((x) => ({ value: x.id, label: x.name }));
    return officeValues;
  }, [offices]);
  const value = options.find((x) => x.value === officeId);
  return (
    <Select
      isDisabled={readonly}
      className={className}
      onChange={(d, event) => {
        if (readonly) {
          return;
        }
        setOfficeId(d?.value ?? '');
      }}
      value={value}
      options={options}
      classNamePrefix={classNamePrefix}
    />
  );
}
