import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormReturn,
  UseFormSetValue
} from 'react-hook-form';
import { useAuthenticated } from '@/lib/api/appUser';
import { SaveReservationData } from '@/lib/utils-reserve';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import SelectUsersAsync from '@/components/users/SelectUsersAsync';

type Props = {
  form: UseFormReturn<any>;
  errors: FieldErrors<SaveReservationData>;
  defaultValue?: { value: string };
  readonly?: boolean;
};
export default function ReserveFieldOrganizer({
  form,
  errors,
  defaultValue,
  readonly = false
}: Props) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const isTeamManager =
    !!authenticatedUser?.user?.permissions.includes(
      'schedule:others:managed:add'
    ) && !authenticatedUser?.user?.permissions.includes('schedule:others:add');

  return (
    <>
      <FormField
        control={form.control}
        name="organizer"
        render={({ field }) => (
          <FormItem>
            <SelectUsersAsync
              defaultValue={[defaultValue?.value ?? '']}
              managed={isTeamManager}
              setInitial={(x) => {
                // Before we can set default values we need to do an async call to retrieve IDs.
                form.setValue('organizer', x[0], { shouldValidate: false });
              }}
              onChange={field.onChange}
              value={field.value}
              className="w-full rounded-md border-gray-300"
              classNamePrefix="react-select"
              placeholder={`Select the Organizer`}
              isMulti={false}
              readonly={readonly}
            />
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}

/**
 * <Controller
        key={JSON.stringify([defaultValue])}
        control={control}
        name="organizer"
        render={({ field: { onChange, value } }) => (
          <SelectUsersAsync
            managed={isTeamManager}
            setInitial={(x) => {
              // Before we can set default values we need to do an async call to retrieve IDs.
              setValue('organizer', x[0], { shouldValidate: false });
            }}
            onChange={onChange}
            value={value}
            className="w-full rounded-md border-gray-300"
            classNamePrefix="react-select"
            placeholder={`Select the Organizer`}
            isMulti={false}
            readonly={readonly}
          />
        )}
      />
 */
