import { MantineProvider, useMantineTheme } from '@mantine/core';
import Head from 'next/head';
import { ReactNode } from 'react';
import AppAuthenticatedProvider from '@/lib/api/appUser';
import { useGetAuthenticatedUser } from '@/hooks/useGetAuthenticatedUser';
import { Chat } from '@/components/basic';
import { AppLayoutBody } from '@/components/layouts/AppLayout/AppLayoutBody';
import { AppLayoutSkeleton } from '@/components/layouts/AppLayout/AppLayoutSkeleton';
import { Simulate } from 'react-dom/test-utils';
import change = Simulate.change;
import { CommonProvider } from '@/contexts/CommonContext';

type AppLayoutProps = {
  children: React.ReactNode;
  hideOfficeMenu?: boolean;
  theme?: string;
  changeTheme?: () => void;
};

const AppLayout = ({
  children,
  hideOfficeMenu = false,
  changeTheme,
  theme
}: AppLayoutProps): JSX.Element => {
  const authenticatedUser = useGetAuthenticatedUser();
  const defaultTheme = useMantineTheme();

  if (!authenticatedUser) {
    return <AppLayoutSkeleton />;
  }

  return (
    <>
      <Head>
        <meta name="googlebot" content="noindex, nofollow, noarchive" />
      </Head>
      {authenticatedUser ? (
        <AppAuthenticatedProvider authenticatedUser={authenticatedUser}>
          <MantineProvider inherit theme={defaultTheme}>
            <div className="isolate flex h-screen flex-col bg-gray-100">
              <Chat />
              <CommonProvider>
                <AppLayoutBody
                  hideOfficeMenu={hideOfficeMenu}
                  theme={theme}
                  changeTheme={changeTheme}
                >
                  {children}
                </AppLayoutBody>
              </CommonProvider>
            </div>
          </MantineProvider>
        </AppAuthenticatedProvider>
      ) : null}
    </>
  );
};

export const getLayout = (page: ReactNode, pageProps: any) => (
  <AppLayout {...pageProps}>{page}</AppLayout>
);

export default AppLayout;
