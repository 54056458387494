import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { api } from '@/lib/api/api';
import { useAuthenticated } from '@/lib/api/appUser';
import { isReservationSurveyEnabled } from '@/lib/featureFlags';

export function useRedirectAfterReserve() {
  const router = useRouter();
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();

  const [afterReserve, setAfterReserve] = useState<false | string>(false);
  const questionnaire = useQuery(
    ['organizations.questionnaire', afterReserve],
    async () => {
      if (!afterReserve) {
        return;
      }
      const res = await api.client.organizations.questionnaire(afterReserve);
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!afterReserve
    }
  );
  const questionnaireData = questionnaire.data;
  useEffect(() => {
    if (
      !afterReserve ||
      !questionnaireData ||
      !isReservationSurveyEnabled(authenticatedUser)
    ) {
      return;
    }
    const shouldRedirect =
      questionnaireData &&
      questionnaireData.is_mandatory &&
      questionnaireData.can_respond &&
      !questionnaireData.is_passed;

    if (shouldRedirect) {
      setAfterReserve(false);
      toast.success('Survey required');
      router.replace(`/questionnaire`);
    }
  }, [questionnaireData, afterReserve, authenticatedUser]);

  return {
    setAfterReserve
  };
}
