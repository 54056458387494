import Image from 'next/image';

export function AppLayoutSkeleton() {
  return (
    <>
      <header className="relative flex h-16 shrink-0 items-center bg-white">
        <div className="absolute inset-y-0 left-0 md:static md:shrink-0">
          <a className="flex h-16 w-16 items-center justify-center bg-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary md:w-28">
            <Image
              src="https://cdn.gettactic.com/tactic_icon_white.svg"
              alt="Tactic icon - Home"
              width={45}
              height={45}
            />
          </a>
        </div>
        <div className="flex w-full md:hidden"></div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden"></div>
        <div className="hidden w-full md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between">
          <div className="flex flex-1 justify-between px-4">
            <div className="flex items-center"></div>
            <div></div>
            <div className="ml-4 flex items-center md:ml-6"></div>
          </div>
        </div>
      </header>
      <div className="flex min-h-0 flex-1 md:overflow-hidden">
        <nav
          aria-label="sidebar"
          className="hidden md:block md:shrink-0 md:bg-secondary"
        >
          <div className="flex h-[calc(100vh-64px)] w-28 flex-col justify-between p-3"></div>
        </nav>
        <div className="w-full min-w-0 border-t border-gray-200 bg-gray-100"></div>
      </div>
    </>
  );
}
