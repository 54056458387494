import { api } from '@/lib/api/api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export default function useAreas(
  officeId: string | null,
  parentId: string | null = null
) {
  const {
    data: areas,
    error,
    isLoading
  } = useQuery(
    ['organizations.resourcesAll', officeId ?? '', ['area'], parentId ?? ''],
    async () => {
      const res = await api.client.organizations.resourcesAll(
        officeId ?? '',
        ['area'],
        parentId
      );
      return res.result;
    },
    {
      enabled: officeId !== null,
      keepPreviousData: true
    }
  );
  const loading = isLoading;
  return { areas, error, loading };
}

export function useFloors(
  officeId: string | null,
  parentId: string | null = null
) {
  const { areas } = useAreas(officeId, parentId);
  const floors = useMemo(
    () =>
      areas?.elements
        ? areas.elements.filter((x) => x.is_available)
        : undefined,
    [areas]
  );
  return { floors };
}

export function useFloorsForOffices(offices: string[]) {
  return useQuery(
    ['organizations.resourcesAll', 'multi-floor', offices],
    async () => {
      const promises = offices.map(async (office) => {
        const res = await api.client.organizations.resourcesAll(office, [
          'area'
        ]);
        return res.result?.elements
          ? res.result?.elements.filter((x) => x.is_available)
          : [];
      });
      return (await Promise.all(promises)).flat();
    }
  );
}
