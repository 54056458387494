import * as mdiIcons from '@mdi/js';
import React, { HTMLProps } from 'react';
import { LucideIcon, LucideIconName } from '../LucideIcon';
import { IconValue } from './IconFromSet.types';

export const IconFromSet: React.FC<{
  icon: IconValue | React.FC | null;
  size?: number;
  color?: string;
}> = ({ icon, size = 8, color }) => {
  if (!icon) return null;

  if (typeof icon === 'object' && !('set' in icon)) {
    const IconComponent = icon as React.FC<HTMLProps<HTMLDivElement>>;
    return (
      <IconComponent
        style={{
          width: size * 4,
          height: size * 4
        }}
      />
    );
  }

  if (typeof icon !== 'object' && (!('set' in icon) || !('icon' in icon))) {
    return null;
  }

  const { icon: iconName, set } = icon;
  if (set === 'mdi')
    return (
      // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
      <svg
        viewBox={'0 0 24 24'}
        width={`${size * 4}px`}
        height={`${size * 4}px`}
        fill={'none'}
      >
        <path
          d={mdiIcons[iconName as never]}
          style={{ fill: color || 'currentColor' }}
        />
      </svg>
    );
  if (set === 'lucide')
    return (
      <LucideIcon name={iconName as LucideIconName} size={size} color={color} />
    );
  //if (set === "custom") return <CustomIcon id={iconName} size={size * 4} />;
  return null;
};
